.header {
  background: #0d0d0d;
  color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  // padding: 2rem 2rem 0 2rem;
  z-index: 100;
  position: fixed;
  width: 100%;

  .logo {
    width: 262px;
    padding: 2rem;
  }

  .iconWrapper {
    display: flex;
  }

  .appStoreIcon {
    padding: 1rem;
    width: 150px;
    height: 67px;
  }
}

@media (max-width: 800px) {
  .header {
    justify-content: center;
    flex-wrap: wrap;

    .logo {
      width: 242px;
    }

    .iconWrapper {
      padding-right: 0px;
    }
  }
}

// @media screen and (max-width: 420px) {
//   .header {
//     flex-direction: column;
//   }
// }
