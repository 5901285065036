@import "./constants/_colors.scss";

.container { 
  min-height: 100vh;
  width: 100vw;
  color: white;
  display: flex;
  // justify-content: center;
  align-items: center;
  // flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  h1, h2, h3 {
    color: white;
    
  }

  .footerContainer  {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 20vh;

  }

  .mobile {
    display: flex; 
    align-content: center; 
    justify-content: center; 
    flex-direction: column; 
    height: 100vh;
    width: 100vw;
  }

  .desktop{ 
    display: flex; 
    align-content: center; 
    justify-content: center; 
    flex-direction: column; 
    height: 100vh;

    background-image: url("../images/BBW_footerimage_desktop.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    width: 100%;
    overflow-y: hidden;
  }

  .bitInfo {
    display: flex;
    justify-content: center;
    text-align: center;
    
    @media screen and (max-width: 1025px) {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      align-items: center;
    }
    
    .bitContent { 
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      // height: 250px;
      width: 250px;

      @media screen and (max-width: 1025px) {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        margin-top: 2em;
      }

      .thumbnailUrl { 
        height: 100%;
        width:  100%; 
      }

      .displayName {
        font-size: 20px !important;
      }
    }

    .qrContent { 
      margin-left: 40px;
      align-items: start !important;
      text-align: left;

      .qrCodeTitle{ 
        font-size: 42px;
        margin-top: 0;
        margin-bottom: 1.5em;
        line-height: 1;
      }

      @media screen and (max-width: 1025px) {
        display: flex;
        justify-content: center;
        // text-align: center;
        align-items: center !important;
        flex-direction: column;
        text-align: center;
        margin: auto;
      }

      .qrCode{
        // height: 50px;
        flex-direction: row;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        
        img {
          height: 120px;
        }

        .qrCodeText { 
          font-size: 20px;
          // height: 50px;
          max-width: 500px;
          margin-left: 25px;
          color: #FFC846 !important;
          margin-top: -5px;
          text-align: left;
        }
      }
    }
    .verticalLine {
      // border-left: 1px solid #FFFFFF;
      background-color: #FFFFFF;
      margin: 0 20px;
      margin-left: 5em;
      // padding-right: 50px;
      height: 270px;
      // align-items: start !important;
    }
  
    .appButtons {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 320px;
      margin-bottom: 15px;
  
      .appsbtnWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      
  
      a {
        margin-bottom: 1em;
        margin-right: 1.5em;
      }
      .appstoreBtn {
        height: 45px;
        margin-left: 1em;
        margin-bottom: 1em;
      }
      @media screen and (max-width: 768px) {
        .appsbtnWrapper {
          display: flex;
          flex-direction: row;
          margin: auto;
        }
  
    
        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
  
          .appstoreBtn {
            height: 50px;
            padding: 0 25px;
          }
        }
      }
      @media screen and (max-width: 420px) {
          a {
            .appstoreBtn {
              height: 40px;
              padding: 0 12px;
              margin: 0;
            }
          }
      }
    }
  }
  

  .bitInfoMobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    margin-top: 7em;
    text-align: center;
    background-image: url("../images/BBW_footerimage_mobile.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    width: 100%;
    // overflow: hidden;

    .titleText{
      font-size: 32px;
    }
    .subTitleText{
      font-size: 20px;
      max-width: 268px;
      color: #FFC846;

    }
    .thumbnailUrl { 
      height: 250px; 
      width: 250px;
    }

    .bitContent {
      text-align: center;
      width: 100%;
      height: 25%;
      // background-image: url("../images/BBW_footerimage_mobile.png");
      // background-size: cover;
      // background-position: bottom;

      .displayName {
        font-size: 20px !important;
      }
    }

    .appButtons {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 320px;
      margin-bottom: 15px;
  
      .appsbtnWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .qrCode{
        height: 50px;
      }
  
      a {
        margin-bottom: 1em;
        margin-right: 1.5em;
      }
      .appstoreBtn {
        height: 45px;
        margin-left: 1em;
        margin-bottom: 1em;
      }
      @media screen and (max-width: 768px) {
        .appsbtnWrapper {
          display: flex;
          flex-direction: row;
          margin: auto;
        }
  
    
        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
  
          .appstoreBtn {
            height: 50px;
            padding: 0 25px;
          }
        }
      }
      @media screen and (max-width: 420px) {
          a {
            .appstoreBtn {
              height: 40px;
              padding: 0 12px;
              // margin: 0;
              margin-top: 1em;
            }
          }
      }
    }




  }


}
