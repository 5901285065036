.loadingContainer {
  // height: 250px;
  width: 250px;
  justify-content: center;
  align-items: center;
  color: #FFC846 !important;

  loadingIcon: {
    height: 250px;
    color: #FFC846 !important;
  }
} 